<template>

    <div>

        <b-card no-body class="mb-0">

            <div class="m-2">
                <b-row>
                    <b-col cols="12" md="3"
                        class="d-flex align-items-center justify-content-start mb-1 mb-md-0 mobile_tab_max_width_flex mobile_margin_bottom_1rem">
                        <label>Show</label>
                        <v-select v-model="perPage" :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                            :options="perPageOptions" :clearable="false" class="per-page-selector d-inline-block mx-50"
                            @input="filterTable" />
                        <label>entries</label>
                    </b-col>

                    <b-col cols="12" md="9" class="mobile_tab_max_width_flex all_btn_tab">
                        <div class="d-flex align-items-center justify-content-end mobile-view mobile_tab_display_block">
                            <b-button :disabled="!(selected.length > 0)" variant="primary" class=" "
                                v-if="checkPermission($route.name, 'Download')"
                                @click="downloadPdf();">
                                <span class="text-nowrap">Download</span>
                            </b-button>
                        </div>
                    </b-col>
                </b-row>

            </div>

            <b-table sticky-header ref="refUserListTable" class="position-relative midTableRpt common_big_tbl"
                :items="dataList" responsive :fields="tableColumns" primary-key="id" :sort-by.sync="sortBy" show-empty
                empty-text="No matching records found" :sort-desc.sync="isSortDirDesc" :class="'remove_overflow'">

                <template #cell(info)="items">
                    <feather-icon :icon="items.detailsShowing ? 'EyeIcon' : 'EyeOffIcon'"
                        @click="items.toggleDetails" />
                </template>
                <template #head(checkbox)="items">
                    <b-form-checkbox class="custom-control-warning p-0" v-model="selectAll" @change="selectall" />
                </template>

                <template #cell(checkbox)="items">
                    <b-form-checkbox v-model="selected" :value="items.item" class="custom-control-warning my-1" />
                </template>

                <template #cell(supplier)="items">
                    <span class="text-capitalize">
                        {{ items.item.supplier_name }}
                    </span>
                </template>

                <template #cell(sites)="items">
                    <span class="text-capitalize">
                        {{ items.item.sites_names }}
                    </span>
                </template>

                <template #cell(moq)="items">
                    <span class="text-capitalize" @click="items.toggleDetails" v-if="items.item.moq">
                        ${{ items.item.moq | currency}}
                    </span>
                    <span class="text-capitalize" @click="items.toggleDetails" v-else>
                        N/A
                    </span>
                </template>

                <template #cell(delivery_fee)="items">
                    <span class="text-capitalize" @click="items.toggleDetails">
                        ${{ items.item.delivery_fee | currency}}
                    </span>
                </template>

                <template #cell(ordered_amount)="items">
                    <!-- <span class="text-capitalize">${{ items.item.total_from_area }}</span> -->
                    <b-badge pill :variant="variantColor(items.item.moq_fulfiled)" class="text-capitalize">
                        ${{ items.item.total_from_area | currency}}
                    </b-badge>
                </template>

                <template #row-details="items">
                    <div class="p-1 px-2 fbDetail mb-1">
                        <b-row>
                            <b-col cols="12" xl="12">
                                <table class="mt-2 mt-xl-0 w-100">
                                    <tr class="mb-1">
                                        <th class="pb-50" style="width: 10%">
                                            <div class="d-flex align-items-center">

                                                <div class="ml-1">
                                                    <h6 class="mb-0 mediumFonts">
                                                        Fee Waivable
                                                    </h6>
                                                </div>
                                            </div>
                                        </th>
                                        <td class="pb-50 mediumFonts" style="width: 15%;padding-top: 8px;">
                                            <span class="text-capitalize"
                                                v-if="items.item.fee_weiver == true">Yes</span>
                                            <span class="text-capitalize" v-else>No</span>
                                        </td>
                                        <th class="pb-50" style="width: 10%">
                                            <div class="d-flex align-items-center">

                                                <div class="ml-1">
                                                    <h6 class="mb-0 mediumFonts">
                                                        MOQ Fulfiled?
                                                    </h6>
                                                </div>
                                            </div>
                                        </th>
                                        <td class="pb-50 mediumFonts" style="width: 15%;padding-top: 8px;">
                                            <span class="text-capitalize"
                                                v-if="items.item.moq_fulfiled == true">Yes</span>
                                            <span class="text-capitalize" v-else>No</span>
                                        </td>
                                        <th class="pb-50" style="width: 10%">
                                            <div class="d-flex align-items-center">
                                                <div class="ml-1">
                                                    <h6 class="mb-0 mediumFonts">
                                                        Month
                                                    </h6>
                                                </div>
                                            </div>
                                        </th>
                                        <td class="pb-50 mediumFonts" style="width: 15%;padding-top: 8px;">
                                            <span class="text-capitalize">{{ selectedMonth }}</span>
                                        </td>
                                    </tr>

                                    <tr class="mb-1">
                                        <th class="pb-50" style="width: 10%">
                                            <div class="d-flex align-items-center">
                                                <div class="ml-1">
                                                    <h6 class="mb-0 mediumFonts">
                                                        Order Type
                                                    </h6>
                                                </div>
                                            </div>
                                        </th>
                                        <td class="pb-50 mediumFonts" style="width: 15%;padding-top: 8px;">
                                            <span class="text-capitalize">{{ items.item.order_type }}</span>
                                        </td>
                                        <th class="pb-50" style="width: 10%">
                                            <div class="d-flex align-items-center">

                                                <div class="ml-1">
                                                    <h6 class="mb-0 mediumFonts">
                                                        Ordered Items Qty
                                                    </h6>
                                                </div>
                                            </div>
                                        </th>
                                        <td class="pb-50 mediumFonts" style="padding-top: 8px;width: 15%;">
                                            {{ items.item.ordered_items_qty }}
                                        </td>
                                        <th class="pb-50" style="width: 10%">
                                            <div class="d-flex align-items-center">

                                                <div class="ml-1">
                                                    <h6 class="mb-0 mediumFonts">
                                                        Area In-Charge
                                                    </h6>
                                                </div>
                                            </div>
                                        </th>
                                        <td class="pb-50 mediumFonts" style="width: 25%;padding-top: 8px;">
                                            {{ items.item.sites_in_charge }}
                                        </td>
                                    </tr>
                                    <tr class="mb-1">
                                        <th class="pb-50" style="width: 16%">
                                            <b-button v-ripple.400="'rgba(113, 102, 240, 0.15)'"
                                                variant="outline-secondary" @click="items.toggleDetails" size="sm"
                                                class="ml-1">
                                                Hide Detail
                                            </b-button>
                                        </th>
                                        <td class="pb-50 mediumFonts" style="width: 17%;padding-top: 8px;">

                                        </td>
                                        <th class="pb-50" style="width: 16%">
                                        </th>
                                        <td class="pb-50 mediumFonts" style="width: 17%;padding-top: 8px;">

                                        </td>
                                        <th class="pb-50" style="width: 16%">
                                        </th>
                                        <td class="pb-50 mediumFonts" style="width: 17%;padding-top: 8px;">
                                        </td>
                                    </tr>
                                </table>
                            </b-col>
                        </b-row>
                    </div>
                </template>
            </b-table>

            <!-- Pagination -->
            <div class="mx-2 mb-2">
                <b-row>
                    <b-col cols="12" sm="6"
                        class="d-flex align-items-center justify-content-center justify-content-sm-start">
                        <span class="text-muted">Showing {{ from }} to {{ to }} of {{ totalRecords }}</span>
                    </b-col>
                    <b-col cols="12" sm="6"
                        class="d-flex align-items-center justify-content-center justify-content-sm-end">
                        <b-pagination v-model="currentPage" :total-rows="totalRecords" :per-page="perPage" first-number
                            last-number class="mb-0 mt-1 mt-sm-0" prev-class="prev-item" next-class="next-item"
                            @input="filterTable">
                            <template #prev-text>
                                <feather-icon icon="ChevronLeftIcon" size="18" />
                            </template>
                            <template #next-text>
                                <feather-icon icon="ChevronRightIcon" size="18" />
                            </template>
                        </b-pagination>
                    </b-col>
                </b-row>
            </div>
        </b-card>
    </div>
</template>

<script>
import {
    BCard, BRow, BCol, BFormInput, BButton, BTable, BMedia, BAvatar, BLink, BFormGroup, BFormRadio,
    BBadge, BDropdown, BDropdownItem, BPagination, BBreadcrumb, BCardBody, BAlert, VBTooltip, BFormCheckbox, BForm, BFormSelect, BFormSelectOption
} from 'bootstrap-vue'
import vSelect from 'vue-select'
import store from '@/store'
import { ref, onUnmounted } from '@vue/composition-api'
import { POST_API } from "../../../store/actions.type"
import Ripple from 'vue-ripple-directive';
import moment from "moment";
import Bus from "../../../event-bus";
import axios from 'axios';

export default {
    props: ["selectedArea", "selectedSupplier", "selectedMonth"],
    components: {
        BAlert,
        BCard,
        BRow,
        BCol,
        BFormInput,
        BButton,
        BTable,
        BMedia,
        BAvatar,
        BLink,
        BBadge,
        BDropdown,
        BDropdownItem,
        BPagination,
        BCardBody,
        vSelect,
        BBreadcrumb,
        BFormGroup,
        BFormCheckbox,
        BForm,
        BFormSelect,
        BFormSelectOption,
        BFormRadio
    },
    directives: {
        Ripple,
        'b-tooltip': VBTooltip
    },
    watch: {
        selectedArea: function (newVal, oldVal) { // watch it
            this.filterTable();
        },
        selectedSupplier: function (newVal, oldVal) { // watch it
            this.filterTable();
        },
        selectedMonth: function (newVal, oldVal) {
            this.filterTable();
        }
    },
    data() {
        return {

            tableColumns: [
                { key: 'checkbox', label: '', sortable: false, thStyle: { width: '1%' } },
                { key: 'info', label: 'INFO', sortable: false, thStyle: { width: '1%' } },
                { key: 'supplier', label: 'Supplier Name', sortable: false, thStyle: { width: '23%' } },
                { key: 'sites', label: 'Project Sites', sortable: false, thStyle: { width: '25%' } },
                { key: 'moq', label: 'MOQ', sortable: false, thStyle: { width: '10%' } },
                { key: 'delivery_fee', label: 'Delivery Fee', sortable: false, thStyle: { width: '15%' } },
                { key: 'ordered_amount', label: 'Total Amount Ordered', sortable: false, thStyle: { width: '20%' } },
            ],

            sites: [],
            suppliers: [],
            from: 0,
            to: 0,
            items: [],
            isSortDirDesc: false,
            sortBy: 'id',
            perPageOptions: [10, 20, 50, 100],
            perPage: 10,
            totalRecords: 0,
            currentPage: 1,

            error_message: '',
            popshowDismissibleAlert: false,
            selectAll: false,
            selected: [],
            currentItem: null,
            pdf_type: 'pdf',
        }
    },
    methods: {
        showAlert() {
            Swal.fire({
                position: 'center',
                icon: 'error',
                text: this.error_message,
                showConfirmButton: false,
                timer: 3000
            })
        },
        downloadPdf() {
            return this.$store.dispatch(POST_API, {
                data: {
                    moq_reports: this.selected,
                    area: this.selectedArea,
                    month: this.selectedMonth,
                },
                api: '/api/inventory-moq-pdf-download'
            }).then(() => {
                if (this.$store.getters.containsErrors) {
                    this.error_message = this.$store.getters.getErrors;
                    this.showAlert();
                } else {
                    var path = this.$store.getters.getResults.data;
                    window.open(path, '_blank')
                }
            })
        },

        dataList() {
            return this.$store.dispatch(POST_API, {
                data: {
                    page: this.currentPage,
                    keyword: this.searchQuery,
                    rowsPerPage: this.perPage,
                    role: this.$store.getters.currentUser.role,
                    area: this.selectedArea,
                    supplier: this.selectedSupplier,
                    month: this.selectedMonth,
                },
                api: '/api/inventory-moq-list'
            })
                .then(() => {
                    if (this.$store.getters.containsErrors) {
                        this.error_message = this.$store.getters.getErrors;
                        this.showAlert();
                    } else {
                        this.items = this.$store.getters.getResults.data.docs;
                        this.totalRecords = this.$store.getters.getResults.data.total;
                        this.perPage = this.$store.getters.getResults.data.limit;
                        this.from = this.$store.getters.getResults.data.from;
                        this.to = this.$store.getters.getResults.data.to;
                        return this.items;
                    }
                });
        },

        filterTable() {
            this.$refs.refUserListTable.refresh();
            this.selected = [];
            this.selectAll = false;
        },
        selectall(e) {
            this.selected = [];
            if (e) {
                console.log(this.items)
                this.selected = this.items;
            }
        },
        variantColor(status) {
            if (status == false) {
                return 'danger';
            } else {
                return 'success';
            }
        }

    },
    mounted() {
    }

}
</script>

<style lang="scss" scoped>
.per-page-selector {
    width: 90px;
}

.border-danger {
    border: 2px solid #ea5455 !important;
    padding: 2px !important;
}

.border-secondary {
    border: 2px solid rgb(221, 221, 221) !important;
    padding: 2px !important;
}

.card-body {
    padding-top: 1rem !important;
}
</style>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
</style>